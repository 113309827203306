.footer {
    background-color: rgba(0, 0, 0, 0.2);
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100% !important;
  }
  
  .footer-content {
    padding: 10px;
  }
  
  .footer-link {
    color: black;
  }