/* Overlay styling */
.update-machine-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10050;
  animation: fadeInOverlay 0.3s ease-in-out;
}

@keyframes fadeInOverlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.update-machine-modal {
  /* Modal styling */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10050;
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  width: 100%;
  animation: fadeInModal 0.3s ease-in-out;
}

@keyframes fadeInModal {
  from {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}



.update-machine-modal form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.update-machine-modal .machine-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.update-machine-modal .counter-text {
  font-size: 14px;
  color: #333;
}

.update-machine-modal .font-weight-bold {
  font-weight: bold;
}

.update-machine-modal img {
  border-radius: 8px;
  max-width: 100%;
  height: auto;
}

.update-machine-modal input[type="text"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.update-machine-modal .text-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.update-machine-modal .text-center button {
  flex: 1;
  margin: 0 5px;
}
.image-container {
  position: relative;
  overflow: hidden;
  width: auto;
  display: block;
  margin: 0 auto;
}

.zoom-image {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
}

.image-container:hover .zoom-image {
  transform: scale(3); /* Adjust the scale as needed */
}